//
// Theme style
//

@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Mulish:wght@400;500;600;700&display=swap');

// Mode
$mode: default;

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';
@import './core/layout/docs/layout';

.page-title {
  h1 {
    font-family: 'DM Serif Display', 'sans-serif';
  }
}

.export-button:not(:hover) {
  color: #000;

  path,
  rect {
    fill: #000 !important;
  }
}

.filter-users-button {
  border-radius: 6px;
  font-weight: bold;
  right: 16px;
  position: absolute;
  bottom: 16px;

  &:hover {
    opacity: 0.8;
  }
}